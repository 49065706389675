import plugin_vue3_BGUoUF9CsF from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/@pinia+nuxt@0.4.11_typescript@5.1.3_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_LjQZj3gdH1 from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.5.3_@types+node@16.18.31_eslint@8.40.0_typescript@5.1.3_vue-tsc@1.8.3/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/.nuxt/components.plugin.mjs";
import unhead_Rq625drLtC from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.5.3_@types+node@16.18.31_eslint@8.40.0_typescript@5.1.3_vue-tsc@1.8.3/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ys9PCJqjF0 from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.5.3_@types+node@16.18.31_eslint@8.40.0_typescript@5.1.3_vue-tsc@1.8.3/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_406uORxEuZ from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.5.3_@types+node@16.18.31_eslint@8.40.0_typescript@5.1.3_vue-tsc@1.8.3/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_0Nn0lUyaBn from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.11_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_3F07EH54hE from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.11_vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_CnaF3xwJwC from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.5.3_@types+node@16.18.31_eslint@8.40.0_typescript@5.1.3_vue-tsc@1.8.3/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_xjhZHLygoo from "/home/runner/work/mfx-framework/mfx-framework/node_modules/.pnpm/nuxt@3.5.3_@types+node@16.18.31_eslint@8.40.0_typescript@5.1.3_vue-tsc@1.8.3/node_modules/nuxt/dist/app/plugins/payload.client.js";
import datadog_client_cfAAU11B0P from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/plugins/datadog.client.ts";
import pinia_persisted_state_client_EVg1kwsoXl from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/plugins/pinia-persisted-state.client.ts";
import pinia_shared_state_client_KrOXCF7p7p from "/home/runner/work/mfx-framework/mfx-framework/apps/mfx-studio/plugins/pinia-shared-state.client.ts";
export default [
  plugin_vue3_BGUoUF9CsF,
  revive_payload_client_LjQZj3gdH1,
  components_plugin_KR1HBZs4kY,
  unhead_Rq625drLtC,
  router_ys9PCJqjF0,
  prefetch_client_406uORxEuZ,
  composition_0Nn0lUyaBn,
  i18n_3F07EH54hE,
  chunk_reload_client_CnaF3xwJwC,
  payload_client_xjhZHLygoo,
  datadog_client_cfAAU11B0P,
  pinia_persisted_state_client_EVg1kwsoXl,
  pinia_shared_state_client_KrOXCF7p7p
]