export type Version =
  | '@visiontree/mfx-auto-renderer@^1.0.0'
  | '@visiontree/mfx-auto-renderer@^0.5'

export const versionOptions: Version[] = [
  '@visiontree/mfx-auto-renderer@^1.0.0',
  '@visiontree/mfx-auto-renderer@^0.5',
]

export const defaultVersion: Version = versionOptions[0]
