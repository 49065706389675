import deepmerge from 'deepmerge'
import merge from 'lodash.merge'
import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

import type { ConfigTypes } from '@visiontree/mfx-auto-renderer'

import type { Questionnaire } from 'fhir/r4'

import { defaultVersion, type Version } from '@/utils/autoRendererVersions'
import {
  type CheckResult,
  mfxAutoRendererQuestionnaireChecks,
  specificationChecks,
} from '@/utils/questionnaireChecks'
import type { MfxAutoRendererV1Config } from '@/utils/tempMfxAutoRendererV1Config.type'

export interface Source {
  id: string
  displayName: string
  jsonString: string
  unedited: boolean
  isValidJSON: boolean
  checks: {
    warningCount: number
    errorCount: number
    successCount: number
    specification: Record<string, CheckResult>
    autoRenderer: Record<string, CheckResult>
  }
  config: ConfigTypes | MfxAutoRendererV1Config
  version: Version
}

export type CodeSuggestion = Record<string, unknown>

interface State {
  questionnaires: Source[]
  /** Source used by the MFX Studio */
  active?: Source
  suggestions: CodeSuggestion[]
}

/**
 * Returns true if the string can be parsed into a valid JSON object.
 */
const checkValidJSON = (jsonString: string) => {
  try {
    const parsed = JSON.parse(jsonString) as unknown

    return typeof parsed === 'object' && !Array.isArray(parsed)
  } catch (error) {
    return false
  }
}

/**
 * Returns a simple questionnaire to give the user a hint as to what can be changed after creating
 * a new questionnaire.
 */
const buildInitialQuestionnaire = (title = 'Untitled'): Questionnaire => {
  return {
    resourceType: 'Questionnaire',
    status: 'draft',
    title,
    item: [],
  }
}

/**
 * Checks if the jsonString matches the initial questionnaire.
 */
const isInitialQuestionnaire = (jsonString: string) => {
  return JSON.stringify(buildInitialQuestionnaire(), null, 2) === jsonString
}

const removeNullProperties = (value: Record<string, unknown>) => {
  for (const key in value) {
    if (value[key] === null) {
      delete value[key]
    } else if (typeof value[key] === 'object') {
      removeNullProperties(value[key] as Record<string, unknown>)
    }
  }
}

const getCheckResults = (sourceString: string) => {
  let sourceObject: Partial<Questionnaire>

  try {
    sourceObject = JSON.parse(sourceString) as Partial<Questionnaire>
  } catch (error) {
    // 🤷‍♂️ not parsable, but that's sometimes expected.
  }
  const specification = Object.entries(specificationChecks).reduce(
    (checks, [checkName, checkFunction]) => {
      return { ...checks, [checkName]: checkFunction(sourceObject) }
    },
    {}
  )

  const autoRenderer = Object.entries(
    mfxAutoRendererQuestionnaireChecks
  ).reduce((checks, [checkName, checkFunction]) => {
    return { ...checks, [checkName]: checkFunction(sourceObject) }
  }, {})

  const checks: CheckResult[] = [
    ...Object.values<CheckResult>(autoRenderer),
    ...Object.values<CheckResult>(specification),
  ]

  return {
    successCount: checks.filter((check) => check.success).length,
    errorCount: checks.filter(
      (check) => !check.success && check.severity === 'error'
    ).length,
    warningCount: checks.filter(
      (check) => !check.success && check.severity === 'warning'
    ).length,
    specification,
    autoRenderer,
  }
}

/**
 * Converts the questionnaire to source metadata.
 */
const buildQuestionnaireSource = (
  questionnaire = buildInitialQuestionnaire()
): Source => {
  const questionnaireString = JSON.stringify(questionnaire, null, 2)

  return {
    id: `q-${uuid()}`,
    displayName: questionnaire.title || 'Untitled',
    jsonString: questionnaireString,
    unedited: isInitialQuestionnaire(questionnaireString),
    isValidJSON: checkValidJSON(questionnaireString),
    checks: getCheckResults(questionnaireString),
    config: {},
    version: defaultVersion,
  }
}

/**
 * Global state for questionnaire sources.
 */
export const useSourcesStore = defineStore('sources', {
  persist: {
    /**
     * Makes sure there's always at least one questionnaire in store and that it's selected for the
     * mfx-studio so that there's never an empty state.
     */
    afterRestore: (context) => {
      const store = context.store as ReturnType<typeof useSourcesStore>

      if (!store.questionnaires.length) {
        store.create()
      } else if (!store.active) {
        store.select(store.questionnaires.at(0))
      }

      store.questionnaires = store.questionnaires.map((source) => {
        return {
          ...source,
          unedited: isInitialQuestionnaire(source.jsonString),
          isValidJSON: checkValidJSON(source.jsonString),
          checks: getCheckResults(source.jsonString),
          config: source.config || {},
          version: source.version || defaultVersion,
        }
      })

      store.clearSuggestions()
    },
  },
  state: (): State => {
    return {
      questionnaires: [],
      suggestions: [],
      active: undefined,
    }
  },
  getters: {
    emptyStateQuestionnaire(state) {
      return state.questionnaires.find((questionnaire) => {
        return questionnaire.unedited
      })
    },
    /** Returns the source with the matching id if present. */
    getById(state) {
      return (id: string) => {
        return state.questionnaires.find((questionnaire) => {
          return questionnaire.id === id
        })
      }
    },
    getSuggestedJsonString(state) {
      return (source?: Source, suggestions?: CodeSuggestion[]) => {
        if (!source) {
          return ''
        }

        if (!source.isValidJSON) {
          return source.jsonString
        }

        if (!state.suggestions.length) {
          return source.jsonString
        }

        let suggestionsToMerge = this.suggestions
        if (suggestions) {
          suggestionsToMerge = suggestions
        }

        const mergedResult = deepmerge.all(
          [JSON.parse(source.jsonString) as object, ...suggestionsToMerge],
          {
            arrayMerge(target, source, options) {
              const output: (object | undefined)[] = []

              source.forEach((item, index) => {
                if (typeof output[index] === 'undefined') {
                  output[index] = options?.cloneUnlessOtherwiseSpecified(
                    item as object,
                    options
                  )
                } else if (options?.isMergeableObject(item as object)) {
                  output[index] = merge(
                    target[index] as object,
                    item as object,
                    options
                  )
                } else if (target.indexOf(item) === -1) {
                  output.push(item as object)
                }
              })

              return output
            },
          }
        )

        removeNullProperties(mergedResult as Record<string, unknown>)
        return JSON.stringify(mergedResult, null, 2)
      }
    },
  },
  actions: {
    /**
     * Creates a new source and selects it for the mfx-studio.
     */
    create(
      questionnaire?: Questionnaire,
      config?: ConfigTypes | MfxAutoRendererV1Config
    ) {
      // The emptyStateQuestionnaire acts as the initial placeholder for new questionnaires, if it's
      // untouched then we'll just try to edit that one instead of making a new empty questionnaire.
      const initialEmptyQuestionnaire = this.emptyStateQuestionnaire
      if (initialEmptyQuestionnaire) {
        if (questionnaire) {
          this.update(initialEmptyQuestionnaire, {
            jsonString: JSON.stringify(questionnaire, null, 2),
          })
        }

        if (config) {
          this.updateConfig(initialEmptyQuestionnaire, config)
        }

        this.select(initialEmptyQuestionnaire)

        return initialEmptyQuestionnaire
      }

      const source = buildQuestionnaireSource(questionnaire)

      if (config) {
        source.config = config
      }

      this.questionnaires.push(source)
      this.select(source)

      return source
    },

    /**
     * Removes the source from the store. Makes sure the store isn't empty and updates the mfx-studio
     * source if the removed source was the active source.
     */
    remove(source?: Source) {
      if (!source) {
        return
      }

      // If the source is unedited and is the only questionnaire remaining then we'll leave it to
      // act as the empty state.
      if (source.unedited && this.questionnaires.length === 1) {
        return
      }

      this.questionnaires = this.questionnaires.filter((current) => {
        return source.id !== current.id
      })

      if (!this.questionnaires.length) {
        this.create()
      } else if (source.id === this.active?.id) {
        this.select(this.questionnaires.at(0))
      }
    },

    /**
     * Updates the source with the provided changes.
     */
    update(source: Source | undefined, changes: Pick<Source, 'jsonString'>) {
      if (!source) {
        return
      }

      const index = this.questionnaires.findIndex((current) => {
        return current.id === source.id
      })

      const updatedSource = {
        ...this.questionnaires[index],
        jsonString: changes.jsonString,
        isValidJSON: checkValidJSON(changes.jsonString),
        unedited: isInitialQuestionnaire(changes.jsonString),
      }

      if (updatedSource.isValidJSON) {
        const json = JSON.parse(changes.jsonString) as Record<string, unknown>

        updatedSource.displayName =
          typeof json?.title === 'string'
            ? json.title
            : updatedSource.displayName

        updatedSource.checks = getCheckResults(changes.jsonString)
      }

      this.questionnaires[index] = updatedSource

      return this.questionnaires[index]
    },

    updateConfig(
      source: Source | undefined,
      changes: Partial<ConfigTypes> | Partial<MfxAutoRendererV1Config>
    ) {
      if (!source) return

      const config = merge(source.config ?? {}, changes)

      if ('ui' in config && config.ui && config.ui.layout !== 'proms') {
        config.ui.promsSettings = undefined
      }

      this.setConfig(source, config)
    },

    setConfig(
      source: Source | undefined,
      config: ConfigTypes | MfxAutoRendererV1Config
    ) {
      if (!source) return

      source.config = config
      const questionnaire = this.questionnaires.find(
        (questionnaire) => questionnaire.id === source.id
      )
      if (questionnaire) {
        questionnaire.config = config
      }
    },

    /**
     * Sets the mfx-studio source.
     */
    select(source?: Source) {
      this.active = source
    },

    addSuggestion(suggestion: CodeSuggestion) {
      if (Object.keys(suggestion).length > 0) {
        this.suggestions.push(suggestion)
      }
    },

    clearSuggestions() {
      this.suggestions = []
    },

    applyAllSuggestions(source?: Source) {
      this.update(source, { jsonString: this.getSuggestedJsonString(source) })
      this.clearSuggestions()
    },

    applySuggestion(source?: Source, json?: CodeSuggestion) {
      if (json) {
        this.update(source, {
          jsonString: this.getSuggestedJsonString(source, [json]),
        })
        this.removeSuggestion(json)
      }
    },

    removeSuggestion(json?: CodeSuggestion) {
      if (json) {
        const insideContent = JSON.stringify(json)

        this.suggestions = this.suggestions.filter((suggestion) => {
          return JSON.stringify(suggestion) !== insideContent
        })
      }
    },
  },
})
